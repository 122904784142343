import React, { useState } from 'react';
import LoanCalculatorTable from './LoanCalculatorTable';
import './LoanCalculator.css'; // Custom styles
import { IoMdAddCircle } from "react-icons/io";
import { RiIndeterminateCircleFill } from "react-icons/ri";
import Banner from '../../components/Banner';
import servicesBanner from '../../images/products/calculator.png';
import { Link } from 'react-router-dom';

// Function to format numbers with commas
const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// Function to format currency with £
const formatCurrency = (num) => {
  if (typeof num !== 'number' || isNaN(num)) {
    return '£0.00'; // Default value if not a number
  }
  return `£${formatNumber(num.toFixed(2))}`; // Ensure num is a number
};

const ScrollCalculator = () => {
  const [loanAmount, setLoanAmount] = useState(1000);
  const [interestRate, setInterestRate] = useState(5); // Default interest should be a reasonable rate
  const [loanDuration, setLoanDuration] = useState(12);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  const durations = [12, 24, 36, 48, 60, 72];

  const calculateLoan = () => {
    const monthlyInterestRate = interestRate / 100 / 12;
    const monthly =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -loanDuration));

    const totalInt = monthly * loanDuration - loanAmount;

    // Set state while ensuring valid number
    setMonthlyPayment(isNaN(monthly) ? 0 : monthly);
    setTotalInterest(isNaN(totalInt) ? 0 : totalInt);
    setTotalCost(isNaN(totalInt + loanAmount) ? 0 : totalInt + loanAmount);
  };

  return (
    <div className='my-[5rem] py-6'>
      <Banner name="Rate Comparison Tool" bannerImg={servicesBanner} />
      <div className="text-center mt-10">
        <h2 className='text-darkYellow font-bold text-2xl md:text-4xl'>
          Rate Comparison Tool
        </h2>
        <p className='text-lg md:text-xl text-gray-600'>
          Compare the cost of your business loan quotes with this handy rate comparison tool
        </p>
      </div>

      <div className='mx-[1rem] md:mx-[5rem] my-6'>
        <h2 className='text-xl font-semibold'>Compare business loan rates</h2>
        <p className='text-gray-600'>
          At Maestro, we believe business loans should be priced fairly and transparently.
        </p>
      </div>

      <div className="loan-calculator grid gap-6 grid-cols-1 md:grid-cols-2">
        <div className="calculator-form bg-white p-6 shadow-md rounded-md">
          <h2 className="font-bold text-xl">Want to understand the cost of your loan?</h2>
          <p className="mb-4">
            Use our business loan calculator below to find out how much you can borrow to take your business to the next level.
          </p>
          <div className="form-group mb-4">
  <label className="font-semibold">Loan amount</label>
  <input
    type="text"  // Changed from "number" to "text" to allow for currency formatting
    value={loanAmount !== null ? `£${loanAmount.toLocaleString('en-GB')}` : ''}
    className="w-full p-2 border border-gray-300 rounded-md"
    onChange={(e) => {
      // Remove non-numeric characters and commas
      const value = e.target.value.replace(/[^\d.]/g, '');
      setLoanAmount(Number(value) || null);
    }}
  />
</div>

<div className="form-group mb-4">
  <label className="font-semibold">Annual interest rate</label>
  <input
  type="text"  // Allows flexible input formatting
  value={interestRate !== null ? `%${interestRate.toFixed(0)}` : ''}
  className="w-full p-2 border border-gray-300 rounded-md"
  onChange={(e) => {
    // Remove non-numeric characters and the % symbol
    const value = e.target.value.replace(/[^\d.]/g, '');
    setInterestRate(Number(value) || null);
  }}
/>

  <small className="text-gray-500">Use 10% if unsure about interest rate.</small>
</div>

          <div className="form-group mb-4">
            <label className="font-semibold">Loan duration</label>
            <div className="duration-options flex gap-2 flex-wrap">
              {durations.map((duration) => (
                <button
                  key={duration}
                  className={`py-2 px-4 rounded-lg ${loanDuration === duration ? 'bg-darkYellow text-white' : 'bg-gray-200'}`}
                  onClick={() => setLoanDuration(duration)}
                >
                  {duration} months
                </button>
              ))}
            </div>
          </div>
          <button onClick={calculateLoan} className="w-full bg-darkYellow hover:bg-yellow-600 text-white font-bold py-3 px-6 mt-4 rounded-md transition duration-300">
            Calculate
          </button>
        </div>

        <div className="calculator-estimate shadow-lg rounded-lg p-6 bg-white text-center md:text-left">
          <h3 className="text-2xl font-bold text-darkYellow mb-4">Your Loan Estimate</h3>

          <div className="estimate-details my-4">
            <p className="text-lg">
              <span className="font-semibold">Monthly payments:</span>
              <span className="text-xl text-black-600"> {formatCurrency(monthlyPayment)}</span>
            </p>
            <p className="text-lg">
              <span className="font-semibold">Monthly interest:</span>
              <span className="text-xl text-black-600"> {formatCurrency(((monthlyPayment - loanAmount / loanDuration) || 0))}</span>
            </p>
            <p className="text-lg">
              <span className="font-semibold">Total interest:</span>
              <span className="text-xl text-black-500"> {formatCurrency(totalInterest)}</span>
            </p>
            <p className="text-lg">
              <span className="font-semibold">Length of loan:</span>
              <span className="text-xl text-black-600"> {loanDuration} months</span>
            </p>
          </div>

          <div className="total-cost my-6 bg-black-100 p-4 rounded-md">
            <h3 className="text-xl font-bold text-darkYellow mb-2">Total Cost of Loan</h3>
            <p className="text-3xl font-bold text-black-600">{formatCurrency(totalCost)}</p>
          </div>

          <Link to="/contact">
            <button className="quote-btn bg-darkYellow hover:bg-yellow-600 text-white font-bold py-3 px-6 mt-4 rounded-lg transition duration-300 ease-in-out shadow-md">
              Get a Quote
            </button>
          </Link>
        </div>
      </div>

      <div className='mx-[1rem] md:mx-[7rem] my-12'>
        <h2 className='text-xl md:text-3xl text-darkYellow font-bold'>Loan Rates</h2>
        <h3 className='text-lg font-bold'>Annual interest rate</h3>
        <p className='text-gray-600 my-4'>
          The annual rate that is charged on a loan. Expressed as a percentage, it represents the actual yearly cost of funding over the term of the loan.
        </p>

        <h2 className='text-xl md:text-3xl text-darkYellow font-bold'>Why did we create this tool?</h2>
        <p className='text-gray-600 my-4'>
          We believe it’s important that businesses know exactly how much they’ll be paying before committing to a loan.
        </p>
      </div>
    </div>
  );
};

export default ScrollCalculator;
